<template>

  <v-container>
    <row-header :msg="msgRowHeader" action="BankNew"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="banks"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="code"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2 green--text"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  class="red--text"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import { GetRequest, DeleteRequest } from '../../../store/services/users';

export default {
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'Bank\'s List', actionName: 'BankNew'},
    banks: [],
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'User', value: 'user' },
      { text: 'Secret Code', value: 'secret_code' },
      { text: 'Url', value: 'base_url' },
      { text: 'Method', value: 'method' },
      { text: 'Inq', value: 'inq_url' },
      { text: 'Pay', value: 'pay_url' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    bankSelected: {},
  }),
  async mounted() {
    console.log('view page admin bank');
    if (this.$store.getters.isLoggedIn) {
      await this.getBankData();
      await this.action.$on('delete-data-bank', () => {
        this.deleteExecute();
      })
    }
  },
  beforeDestroy() {
    // this.action.$off('delete-data-user');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getBankData() {
      this.loading = await this.$store.dispatch('req_data')
      try{
        let response = await GetRequest('banks');
        this.banks = response.data;
        this.banks.forEach((val, i) => {
          val.no = i+1;
          val.from_date = val.from_date ? this.$moment(val.from_date).format('YYYY-MM-DD') : '-';
          val.to_date = val.to_date ? this.$moment(val.to_date).format('YYYY-MM-DD') : '-';
          val.base_url = val.url.url;
          val.method = val.url.method;
          val.inq_url = val.url.url_inq;
          val.pay_url = val.url.url_pay;
        })
        this.loading = await this.$store.dispatch('req_success');
        return true;
      }catch(err){
        this.loading = await this.$store.dispatch('req_failed');
        alert('call bank data failed ' + err.message);
        return false;
      }
    },
    editItem (item) {
      this.bankSelected = item;
      this.$router.push({name: 'BankEdit', params: item})
    },
    deleteItem (item) {
      this.bankSelected = item;
      this.action.$emit('open-dialog-conf-bank', true);
    },
    async deleteExecute () {
      await this.$store.dispatch('req_data')
      this.loading = this.$store.getters.isLoading;
      try {
        let response = await DeleteRequest('banks/' + this.bankSelected._id);
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        // console.log(response);
        if (response.data && response.message === 'deleted') {
          alert('delete data success');
          await this.getBankData();
          return;
        }
        alert('delete data failed');
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Delete data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
